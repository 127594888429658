body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 100%;
  align-items: center;
}

.ant-menu-inline.ant-menu-root .ant-menu-item {
  background-color: transparent;
}

.ant-menu-inline.ant-menu-root .ant-menu-item.ant-menu-item-selected {
  background-color: #e6f7ff;
  border-right: 3px solid #133c57;
  border-radius: 0;
}
